<template>
  <div>
    <component
      :is="carouselComponentName"
      v-if="content"
      :headline="headline"
      :content="content"
    />
  </div>
</template>

<script>
import ProductCarouselTrending from '@/components/organisms/ShopCommerce/ProductCarouselTrending';
import ProductCarouselRecentlyBought from '@/components/organisms/ShopCommerce/ProductCarouselRecentlyBought';
import ProductCarouselNewest from '@/components/organisms/ShopCommerce/ProductCarouselNewest';
import ProductCarouselSmartSearch from '@/components/organisms/ShopCommerce/ProductCarouselSmartSearch';

export default {
  name: 'ProductCarousel',
  components: {
    ProductCarouselTrending,
    ProductCarouselRecentlyBought,
    ProductCarouselNewest,
    ProductCarouselSmartSearch
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    headline: {
      type: String,
      default: ''
    }
  },
  computed: {
    carouselComponentName () {
      if (this.content.getProductsBy === 'Trending') {
        return 'ProductCarouselTrending'
      }
      if (this.content.getProductsBy === 'Recently bought') {
        return 'ProductCarouselRecentlyBought';
      }
      if (this.content.getProductsBy === 'Newest') {
        return 'ProductCarouselNewest';
      }
      return this.content.ncContentTypeAlias
    }
  }
}
</script>
