<template>
  <div>
    <carousel
      v-if="content"
      :content="content"
      :headline="headline"
      :products="products"
    />
  </div>
</template>

<script>
import Carousel from '@/components/organisms/ShopCommerce/Carousel.vue';
import { trackProductImpressions } from '@/utils/enhancedEcomTracking';

export default {
  name: 'ProductCarouselRecentlyBought',
  components: {
    Carousel
  },
  props: {
    content: {
      type: Object,
      default: () => { }
    },
    headline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products: []
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    productsToShow () {
      return this.$isNotNullOrUndefined(this.content.productsToShow) ? this.content.productsToShow : 8
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    template () {
      return this.$store.getters['content/content/getContent']?.meta?.type;
    },
    categoryGuid () {
      return this.$store.getters['content/content/getContent']?.meta?.id;
    },
  },
  mounted () {
    this.getProducts();
  },
  methods: {
    async getProducts () {
      // Recently bought products

      let filters = []
      if (this.content.isCategoryDepended && ['ContentProductList', 'ContentCategory'].includes(this.template)) {
        // If the section is placed on a category or product list
        filters = [{
          id: 'categories',
          values: [this.categoryGuid]
        }]
      }

      try {
        const data = await this.$repositories.products.byRecentlyBought (this.currentCulture, this.productsToShow, 1, filters)
        this.products = await this.$kontainer.getPackshots(data?.items);
        trackProductImpressions(
          this.products,
          'Recently bought products',
          this.currency
        );
      } catch {
        console.log('api error in $repositories.products.byRecentlyBought')
      }
    }
  }
}
</script>
