<template>
  <div>
    <carousel
      v-if="content"
      :content="content"
      :headline="headline"
      :products="products"
    />
  </div>
</template>

<script>
import Carousel from '@/components/organisms/ShopCommerce/Carousel.vue';
import { trackProductImpressions } from '@/utils/enhancedEcomTracking';

export default {
  name: 'ProductCarouselTrending',
  components: {
    Carousel
  },
  props: {
    content: {
      type: Object,
      default: () => { }
    },
    headline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products: []
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    productsToShow () {
      return this.$isNotNullOrUndefined(this.content.productsToShow) ? this.content.productsToShow : 8
    },
    categoryGuid () {
      return this.$store.getters['content/content/getContent']?.meta?.id;
    },
    template () {
      return this.$store.getters['content/content/getContent']?.meta?.type;
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
  },
  mounted () {
    this.getProducts();
  },
  methods: {
    async getProducts () {
      // Trending products
      let categoryId = '';

      if (this.content.isCategoryDepended && ['ContentProductList', 'ContentCategory'].includes(this.template)) {
        // If the section is placed on a category or product list
        categoryId = this.categoryGuid;
      }
      else if (this.$isNotNullOrUndefined(this.content.category) && this.content.category.length) {
        // If the user has picked a category from backoffice
        categoryId = this.content.category[0];
      }

      if (categoryId.length) {
        try {
          const data = await this.$repositories.products.byTrendingCategory (this.currentCulture, categoryId, this.productsToShow, 1, '', '', '', '')
          this.products = await this.$kontainer.getPackshots(data?.items);
          let category = this.products[0].primaryCategoryName;
          trackProductImpressions(
            this.products,
            'Trending in - ' + category,
            this.currency
          );
        } catch {
          console.log('api error in $repositories.products.byTrendingCategory')
        }
      } else {
        try {
          const data = await this.$repositories.products.byTrending (this.currentCulture, this.productsToShow, 1, '', '', '', '')
          this.products = await this.$kontainer.getPackshots(data?.items);
          trackProductImpressions(
            this.products,
            'Trending in all products',
            this.currency
          );
        } catch {
          console.log('api error in $repositories.products.byTrending')
        }
      }
    }
  }
}
</script>
