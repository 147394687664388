<template>
  <div>
    <carousel
      v-if="content"
      :content="content"
      :headline="headline"
      :products="products"
    />
  </div>
</template>

<script>
import Carousel from '@/components/organisms/ShopCommerce/Carousel.vue';
import { trackProductImpressions } from '@/utils/enhancedEcomTracking';

export default {
  name: 'ProductCarouselSmartSearch',
  components: {
    Carousel
  },
  props: {
    content: {
      type: Object,
      default: () => { }
    },
    headline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products: []
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    productsToShow () {
      return this.$isNotNullOrUndefined(this.content.productsToShow) ? this.content.productsToShow : 8
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
  },
  mounted () {
    this.getProducts();
  },
  methods: {
    async getProducts () {
      // Smart search
      let sorting = {
        orderBy: '',
        order: ''
      };

      let filters = [];
      let currentPage = 1;
      let facetsToGet = '';
      let smartSearch = this.content.smartSearch;

      if(smartSearch.categories?.length) {
        let categories = smartSearch.categories;

        if(this.$isNotNullOrUndefined(filters.find(x => x.id === 'categories'))) {
          filters.find(x => x.id === 'categories').values = categories
        } else {
          filters.push(
            {
              id: 'categories',
              values: categories
            }
          )
        }
        filters.categories = {
          id: 'categories',
          values: categories
        }
      }

      if(this.$isNotNullOrUndefined(smartSearch.facetsQueryJson) && smartSearch.facetsQueryJson !== '') {
        let smartSearchQuery = smartSearch.facetsQueryJson;

        if(this.$isNotNullOrUndefined(filters.find(x => x.id === 'smartsearch'))) {
          filters.find(x => x.id === 'smartsearch').values = [smartSearchQuery]
        } else {
          filters.push(
            {
              id: 'smartsearch',
              values: [smartSearchQuery]
            }
          )
        }
        filters.smartsearch = {
          id: 'smartsearch',
          values: [smartSearchQuery]
        }
      }

      try {
        let response = await this.$repositories.products.byPopular(
          this.currentCulture,
          this.productsToShow,
          currentPage,
          facetsToGet,
          sorting.orderBy,
          sorting.order,
          filters
        )
        response.items = await this.$kontainer.getPackshots(response?.items);
        this.products = response.items;

        trackProductImpressions(
          this.products,
          this.$router.currentRoute.path.split('/').slice(-1)[0],
          this.currency
        );
      } catch {
        console.log('api error in $repositories.products.byPopular')
      }
    }
  }
}
</script>
