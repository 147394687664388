<template>
  <div class="m-spots-carousel" :class="{ '-align-right': content.imageRightAligned }">
    <div class="row">
      <div class="col-lg-3" :class="{ 'order-lg-2' : content.imageRightAligned }">
        <div class="m-spots-carousel__content">
          <base-image
            v-if="content.image && content.image[0]"
            :url="content.image[0].Url"

            :width="356"
            :height="545"
            image-class="m-spots-carousel__image"
            :alt-text="'imageAltText'"
          />
          <h2
            v-if="$isNotNullOrUndefined(content.headline) || headline !== ''"
            class="m-spots-carousel__headline"
          >
            {{ headline !== '' ? headline : content.headline }}
          </h2>
        </div>
      </div>
      <div class="px-0 col-lg-9" :class="{ 'order-lg-1' : content.imageRightAligned }">
        <div v-if="buildingSlider" class="o-product-carousel__loader">
          <div class="o-product-carousel__skeleton-container">
            <skeleton-loader layout="card" :aspect-ratio="32/45" class="o-product-carousel__skeleton" />
          </div>
          <div class="o-product-carousel__skeleton-container">
            <skeleton-loader layout="card" :aspect-ratio="32/45" class="o-product-carousel__skeleton" />
          </div>
          <div class="o-product-carousel__skeleton-container d-none d-sm-block">
            <skeleton-loader layout="card" :aspect-ratio="32/45" class="o-product-carousel__skeleton" />
          </div>
          <div class="o-product-carousel__skeleton-container d-none d-md-block">
            <skeleton-loader layout="card" :aspect-ratio="32/45" class="o-product-carousel__skeleton" />
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="$isNotNullOrUndefined(products) && products.length > 0"
            class="m-spots-carousel__glide"
          >
            <vue-glide
              :class="{ 'glide--rtl': content.imageRightAligned }"
              v-bind="glideOptions"
              @glide:mount-after="buildingSlider = false"
            >
              <vue-glide-slide
                v-for="(product, i) in products"
                :key="i"
              >
                <product-card
                  :product="product"
                  :is-recommended="true"
                  show-add-to-cart-button
                />
              </vue-glide-slide>
              <template
                v-if="products.length > glideOptions.perView"
                slot="control"
              >
                <div data-glide-dir="<">
                  <base-arrow
                    class="arrow -left -inverted"
                    aria-label="previous"
                  />
                </div>
                <div data-glide-dir=">">
                  <base-arrow
                    class="arrow -inverted"
                    aria-label="next"
                  />
                </div>
              </template>
            </vue-glide>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
import ProductCard from '@/components/organisms/ShopCommerce/ProductCard.vue';
import BaseArrow from '@/components/atoms/BaseArrow.vue';
import BaseImage from '@/components/atoms/BaseImage.vue';
import SkeletonLoader from '@/components/atoms/SkeletonLoader.vue';

export default {
  name: 'Carousel',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    BaseArrow,
    BaseImage,
    ProductCard,
    SkeletonLoader
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Array,
      default: () => {}
    },
    headline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      buildingSlider: true,
      glideOptions: {
        gap: 30,
        rewind: false,
        perView: 3.5,
        bound: true,
        bullet: true,
        direction: this.content.imageRightAligned ? 'rtl' : 'ltr',
        breakpoints: {
          768: {
            perView: 1.3,
          },
          1024: {
            perView: 2.3,
          }
        }
      }
    };
  }
}

</script>