<template>
  <div>
    <carousel
      v-if="content"
      :content="content"
      :headline="headline"
      :products="products"
    />
  </div>
</template>

<script>
import Carousel from '@/components/organisms/ShopCommerce/Carousel.vue';
import { trackProductImpressions } from '@/utils/enhancedEcomTracking';

export default {
  name: 'ProductCarouselNewest',
  components: {
    Carousel
  },
  props: {
    content: {
      type: Object,
      default: () => { }
    },
    headline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products: []
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
    productsToShow () {
      return this.$isNotNullOrUndefined(this.content.productsToShow) ? this.content.productsToShow : 8
    },
    categoryGuid () {
      return this.$store.getters['content/content/getContent']?.meta?.id;
    },
    currency () {
      return this.$store.getters['currency/getCurrentCurrency'];
    },
    template () {
      return this.$store.getters['content/content/getContent']?.meta?.type;
    }
  },
  mounted () {
    this.getProducts();
  },
  methods: {
    async getProducts () {
      // Newest products for the current category
      let categoryId = '';

      if (this.content.isCategoryDepended && ['ContentProductList', 'ContentCategory'].includes(this.template)) {
        // If the section is placed on a category or product list
        categoryId = this.categoryGuid;
      }
      else if (this.$isNotNullOrUndefined(this.content.category) && this.content.category.length) {
        // If the user has picked a category from backoffice
        categoryId = this.content.category[0];
      }

      if (categoryId.length) {
        try {
          let response = await this.$repositories.products.byCategory(
            categoryId, 
            this.currentCulture,
            this.productsToShow,
            1,
            '',
            'age',
            'asc',
            ''
          );

          response.items = await this.$kontainer.getPackshots(response?.items);
          this.products = response.items;

          trackProductImpressions(
            this.products,
            this.$router.currentRoute.path.split('/').slice(-1)[0],
            this.currency
          );
        } catch {
          console.log('api error in $repositories.products.byPopular')
        }
      }
    }
  }
}
</script>
