<template>
  <div class="a-arrow">
    <button
      class="a-arrow__button"
      type="button"
      :aria-label="ariaLabel"
      v-on="$listeners"
    >
      <!--@slot Use this slot to replace arrow icon-->
      <slot>
        <svg-icon
          class="a-arrow__icon"
          name="icons/chevron-right"
        />
      </slot>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Arrow',
  props: {
    ariaLabel: {
      type: String,
      default: 'Arrow'
    }
  }
};
</script>
